import * as React from "react";
import * as ReactDOM from "react-dom/client";
import MseMPresetLinker from "ublo-lib/es/future/components/msem-preset-linker";
// import MseMPresetLinker from "./msem-preset-linker";

const createParent = (parentId) => {
  const el = document.createElement("div");
  el.id = parentId;
  document.body.appendChild(el);
  return el;
};

const findParent = (parentId) => {
  const targetSelector = `#${parentId}`;
  const target = document.querySelector(targetSelector);
  return target ?? createParent(parentId);
};

const setBodyOverflow = (value) => {
  document.body.style.setProperty("overflow", value);
};

const open = async (
  parentId,
  lang,
  channel,
  resort,
  msemServicesUrl,
  target,
  resorts,
  showUrl
) => {
  const container = findParent(parentId);

  const close = () => {
    setBodyOverflow("");
    root.unmount();
    delete container.reactRoot;
  };

  const buildTree = () => {
    return (
      <React.StrictMode>
        <MseMPresetLinker
          lang={lang}
          channel={channel}
          resort={resort}
          msemServicesUrl={msemServicesUrl}
          target={target}
          resorts={resorts}
          close={close}
          showUrl={showUrl}
        />
      </React.StrictMode>
    );
  };

  // BEGIN : Attention : Hack à conserver pour éviter les multiples render de widgets dans les sites marchands
  let root = container.reactRoot ?? ReactDOM.createRoot(container);
  container.reactRoot = root;

  try {
    root.render(buildTree());
  } catch (e) {
    root = ReactDOM.createRoot(container);
    container.reactRoot = root;
    root.render(buildTree());
  }
  // END : Attention : Hack à conserver pour éviter les multiples render de widgets dans les sites marchands

  return [buildTree, container, root];
};

const editor = ({
  lang,
  channel,
  resort,
  msemServicesUrl,
  target,
  resorts,
  showUrl,
}) => {
  return open(
    "MseMPresetEditor",
    lang,
    channel,
    resort,
    msemServicesUrl,
    target,
    resorts,
    showUrl
  );
};

window.MseMPresetEditor = { ...window.MseMPresetEditor, editor };
